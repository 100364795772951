import React from "react"
import { Layout } from "../components"
const notFound = () => {
  return (
    <Layout>
      <div className="font-semibold text-2xl">
        <p>What you're looking for could not be found.</p>
      </div>
    </Layout>
  )
}

export default notFound
